import { runInAction } from 'mobx';
import React from 'react';

const CheckboxSearch = props => {

    let formControl = "form-control " + props.className;

    return (
        <div className={"form-group form-group--checkbox"}>
            <input type="checkbox"
                name = {props.name}
                value = {props.value}
                checked = {props.isSelected}
                onChange={props.onCheckboxChange}
                className = {'checkbox'}
            />
            {props.label &&
            <React.Fragment>
                {typeof props.label === 'object' && 
                    <label 
                        className={formControl}
                        onClick = {(e) => props.onCheckboxChange()}
                    >
                        {props.label}
                    </label>
                }
                {typeof props.label !== 'object' && 
                    <label 
                        className={formControl} 
                        onClick = {(e) => props.onCheckboxChange()}
                        dangerouslySetInnerHTML={{ __html: props.label }} 
                    />
                }
            </React.Fragment>
            }
        </div>
    );
}

export default CheckboxSearch;