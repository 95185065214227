import React, { PureComponent } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";

import * as content from '../../utilities/content';

import SearchSelectSegment from './search-select-segment';
import SearchOverlayItemTop from './search-overlay-item-top';
import FeedbackNoSearchResults from '../feedback/feedback-no-search-results';
import FeedbackNoSearchResultsSuggestion from '../feedback/feedback-no-search-results-suggestion';
import SearchResultOverlay from './search-result-overlay';
import SearchResultIntentions from './search-result-intentions';
import SearchResultSpellcheck from './search-result-spellcheck';
import SearchResultAlternatives from './search-result-alternatives';

const SearchOverlay = inject("stores") (
    observer (
        class SearchOverlay extends PureComponent {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;

                this.minLength = 5;

                this.state = {
                    results: [],
                    shopAlternative: '',
                    skuAlternative: ''
                }
            }

            componentDidMount = async() => {
                let shop;
                let section;
                let shopAlternative;
                let skuAlternative;
                let count;
               
                switch(this.props.searchShop) {
                    case 'OWN':
                        shop = 'SHOP_SB';
                        section = 'sb'; 
                        break;
                    case 'SB':
                        shop = 'SHOP_SB';
                        section = ''; 
                        break;
                    case 'ALL':
                        shop = 'SHOP_ALL';
                        section = ''; 
                        break;   
                }

                if (this.storeUi.search_input_focus || this.storeUi.search_overlay) {
                    if (this.props.searchShop === 'OWN') {

                    } 

                    if (this.props.searchShop === 'ALL') {
                        const searchAlternative = false;
                        //const results3 = await this.storeUi.getSearchResultsSimple('SHOP_ALL', this.storeUi.search_value, '', this.storeUi.page_view, searchAlternative); 

                        shopAlternative = 'Alle boeken';
                        skuAlternative = 'ALL';
                        this.setState({
                            shopAlternative: shopAlternative,
                            skuAlternative: skuAlternative
                        })
                    }
                } 
            }

            componentDidUpdate = async(prevProps) => {
                let shop;
                let section;
                let shopAlternative;
                let skuAlternative;
                let results;

                if (prevProps.searchInput !== this.props.searchInput || 
                    prevProps.searchInputFocus !== this.props.searchInputFocus || 
                    prevProps.searchShop !== this.props.searchShop) {

                    //console.log('SbC suggestions', this.storeUi.search_results_simple_sb)
                } 

                window.scrollTo(0, 0);
            }

            setLayout = () => {
                let layoutSearch = 'fullwidth';

                if (this.props.searchShop === 'ALL') {
                    layoutSearch = 'fullwidth';
                } else {
                    layoutSearch = 'columns';
                }
                return layoutSearch;
            }

            setOverlayRef = (node) => {
                this.overlayRef = node;
                this.storeUi.setSearchOverlayRef(node);
            }

            feedbackShop = (shop) => {
                let fb = ' in ';
                switch(shop) {
                    case 'OWN':
                        fb = fb + 'Boeken eigen uitgeverij';
                        break;
                    case 'SB':
                        fb = fb + 'Zelfhulpboeken';
                        break;
                    case 'ALL':
                        fb = fb + 'Alle boeken';
                        break;
                    default:   
               }

               return fb;
            }

            onSubmit = () => {
                let search_term;
                search_term = this.storeUi.search_value;

                this.storeUi.setPageView('page', 1);
                this.storeUi.getSearchIntentions(search_term);
                this.storeUi.setSearchOverlay(false);
                this.storeUi.setSearchInputFocus(false);
               
                this.storeUi.saveSearchQuery(search_term);
                this.storeUi.setActiveMainNav(this.storeUi.app_interface.active_search_segment);

                const path = "/search-results";
                const str_search = "?q=" + search_term;

                this.props.history.push({
                    pathname: path,
                    search: str_search
                })
            }

            // handleChangeSegment = (shop) => {
            //     let search_term;
            //     let altShop;
            //     let altShopSearch;
            //     search_term = this.storeUi.search_value;

            //     switch(shop) {
            //         case 'Alle zelfhulpboeken':
            //             altShop = 'SHOP_SB';
            //             altShopSearch = 'SB';
            //             this.storeUi.setActiveToggleNav(2);
            //             break;
            //         case 'Alle boeken':
            //             altShop = 'SHOP_ALL';
            //             altShopSearch = 'ALL';
            //             this.storeUi.setActiveToggleNav(3);
            //             break;
            //         default:   
            //     }

            //     this.storeGeneral.setShop(altShop);
            //     this.storeGeneral.setActiveShop(altShop);
            //     //this.storeGeneral.setSearchShop(altShopSearch);
                
            //     this.storeUi.setPageView('page', 1);
            //     this.storeUi.getSearchIntentions(search_term);
            //     this.storeUi.setSearchOverlay(false);
            //     this.storeUi.setSearchInputFocus(false);
               
            //     this.storeUi.saveSearchQuery(search_term);
            //     this.storeUi.setActiveMainNav(this.storeUi.app_interface.active_search_segment);

            //     const path = "/search-results";
            //     const str_search = "?q=" + search_term;

            //     this.props.history.push({
            //         pathname: path,
            //         search: str_search
            //     })
            // }

            // handleNav = (page) => {
            //     this.storeGeneral.setShop("SHOP_ALL");
            //     this.storeGeneral.setActiveShop("SHOP_ALL");
            //     //this.storeGeneral.setSearchShop('ALL');

            //     this.storeUi.setSearchOverlay(false);
            //     const path = "/";
            //     this.props.history.push({
            //         pathname: path
            //     })
            // }

            render() {
                return (
                    <React.Fragment>
                        {this.storeUi.search_overlay &&
                        <div 
                            ref={this.setOverlayRef}
                            className={"search-overlay" + ((this.storeUi.search_overlay && (this.storeUi.search_value.trim().length >= 3 || content.searchChars(this.storeUi.search_value.trim()))) ? ' --active' : '')}
                        >
                            <div 
                                className = 'search-overlay__columns'
                            >                             
                                <div 
                                    className = 'search-overlay__column search-overlay__column--topitem'
                                >
                                    <SearchOverlayItemTop
                                        searchValue = {this.storeUi.search_value}
                                        searchTopItemTitle = {this.storeUi.searchTopItemTitle}
                                        searchTopItemTitleSku = {this.storeUi.searchTopItemTitleSku}
                                        searchTopItemScore = {this.storeUi.searchTopItemScore}
                                        items = {this.storeUi.search_results_simple_all}
                                    />
                                </div>

                                <div 
                                    className = 'search-overlay__column search-overlay__column--segments'
                                >
                                    <SearchSelectSegment
                                        searchShop = {this.storeGeneral.searchShop}
                                        searchSegment = {this.storeGeneral.searchSegment}
                                    />                                   
                                </div>

                                {/* 
                                    SbC solr no results at all
                                */}
                                {((this.storeGeneral.searchShop === 'OWN' &&
                                    this.storeUi.search_count_own === 0 || this.storeUi.search_count_own === null
                                    ) ||
                                    (this.storeGeneral.searchShop === 'SB' &&
                                        this.storeUi.search_count_sb === 0 || this.storeUi.search_count_sb === null
                                    ) ||
                                    (this.storeGeneral.searchShop === 'ALL' &&
                                        this.storeUi.search_count_all === 0 || this.storeUi.search_count_all === null
                                    )) &&
                                    <div 
                                        className = 'search-overlay__column--feedback'
                                    >
                                        <FeedbackNoSearchResults

                                        />
                                    </div>
                                }
                           
                                {/* 
                                    SbC solr spelling suggestions
                                */}
                                
                                    {this.storeUi.checkFeature('SEARCH_SPELLING') &&
                                    (this.storeUi.search_results_simple_all[0] && this.storeUi.search_value !== this.storeUi.search_results_simple_all[0].sku) &&
                                    <React.Fragment>
                                        {
                                            (
                                                (this.storeGeneral.searchShop === 'OWN' && this.storeUi.search_count_own === 0)
                                            )
                                            ?   this.storeUi.search_results_simple_own &&
                                                this.storeUi.search_results_simple_own[0] && 
                                                this.storeUi.search_results_simple_own[0].spellcheck && 
                                                this.storeUi.search_results_simple_own[0].spellcheck.suggestions && 
                                                this.storeUi.search_results_simple_own[0].spellcheck.suggestions[1] && 
                                                this.storeUi.search_results_simple_own[0].spellcheck.suggestions[1].suggestion && 
                                                    <div 
                                                        className = 'search-overlay__column search-overlay__column--suggestions'
                                                    >
                                                        <SearchResultSpellcheck
                                                            searchValue = {this.storeUi.search_value}
                                                            spellcheck = {this.storeUi.search_results_simple_own[0].spellcheck.suggestions[1].suggestion}
                                                        />
                                                    </div>
                                            :   null
                                        }  

                                        {
                                            (
                                                (this.storeGeneral.searchShop === 'SB' && this.storeUi.search_count_sb === 0)
                                            )
                                            ?   this.storeUi.search_results_simple_sb &&
                                                this.storeUi.search_results_simple_sb[0] && 
                                                this.storeUi.search_results_simple_sb[0].spellcheck && 
                                                this.storeUi.search_results_simple_sb[0].spellcheck.suggestions && 
                                                this.storeUi.search_results_simple_sb[0].spellcheck.suggestions[1] && 
                                                this.storeUi.search_results_simple_sb[0].spellcheck.suggestions[1].suggestion && 
                                                    <div 
                                                        className = 'search-overlay__column search-overlay__column--suggestions'
                                                    >
                                                        <SearchResultSpellcheck
                                                            searchValue = {this.storeUi.search_value}
                                                            spellcheck = {this.storeUi.search_results_simple_sb[0].spellcheck.suggestions[1].suggestion}
                                                        />
                                                    </div>
                                            :   null
                                        }  

                                        {
                                            (
                                                (this.storeGeneral.searchShop === 'ALL' && this.storeUi.search_count_all === 0)
                                            )
                                            ?   this.storeUi.search_results_simple_all &&
                                                this.storeUi.search_results_simple_all[0] && 
                                                this.storeUi.search_results_simple_all[0].spellcheck && 
                                                this.storeUi.search_results_simple_all[0].spellcheck.suggestions && 
                                                this.storeUi.search_results_simple_all[0].spellcheck.suggestions[1] && 
                                                this.storeUi.search_results_simple_all[0].spellcheck.suggestions[1].suggestion && 
                                                    <div 
                                                        className = 'search-overlay__column search-overlay__column--suggestions'
                                                    >
                                                        <SearchResultSpellcheck
                                                            searchValue = {this.storeUi.search_value}
                                                            spellcheck = {this.storeUi.search_results_simple_all[0].spellcheck.suggestions[1].suggestion}
                                                        />
                                                    </div>
                                            :   null
                                        }  
                                    </React.Fragment>
                                    }      

                                    {/* 
                                        SbC db suggestions/intentions in column left
                                    */}
                                    {this.storeUi.checkFeature('SEARCH_INTENT') &&
                                    (this.storeUi.search_results_simple_all[0] && this.storeUi.search_value !== this.storeUi.search_results_simple_all[0].sku) &&
                                    <React.Fragment>
                                    {
                                        (
                                            (this.storeGeneral.searchShop === 'OWN' && this.storeUi.search_count_own === 0) ||
                                            (this.storeGeneral.searchShop === 'SB' && this.storeUi.search_count_sb === 0) ||
                                            (this.storeGeneral.searchShop === 'ALL' && this.storeUi.search_count_all === 0)
                                        )
                                        ?   (this.storeUi.search_count_own === 0 && this.storeUi.search_count_sb === 0 && this.storeUi.search_count_all === 0)
                                            ?   null
                                            :   <SearchResultIntentions

                                                />
                                        :   null
                                    }   
                                    </React.Fragment>
                                    }                    
                               
                                    {/* 
                                        SbC db suggestions/intentions in column right
                                    */}
                                    {this.storeUi.checkFeature('SEARCH_INTENT') &&
                                    (this.storeUi.search_results_simple_all[0] && this.storeUi.search_value !== this.storeUi.search_results_simple_all[0].sku) &&
                                    <React.Fragment>
                                        {
                                        (
                                            (this.storeUi.search_count_own === 0) && (this.storeUi.search_count_sb === 0)  && (this.storeUi.search_count_all === 0)
                                            
                                        )
                                        ?   <div 
                                                className = 'search-overlay__column search-overlay__column--alternatives'
                                            >
                                                <SearchResultIntentions

                                                />
                                            </div> 
                                        :   null
                                    }
                                    
                                    {
                                        (
                                            (this.storeGeneral.searchShop === 'OWN' && this.storeUi.search_count_own === 0) &&
                                            (this.storeUi.search_count_sb > 0 || this.storeUi.search_count_all > 0 ) &&
                                            this.storeUi.search_results_simple_all[0] && (this.storeUi.search_value !== this.storeUi.search_results_simple_all[0].sku)
                                        )
                                        ?   <div 
                                                className = 'search-overlay__column search-overlay__column--alternatives'
                                            >
                                                <SearchResultAlternatives
                                                    bemClass = "--column"
                                                />
                                            </div>
                                        :   null
                                    }

                                    {
                                        (
                                            (this.storeGeneral.searchShop === 'SB' && this.storeUi.search_count_sb === 0) &&
                                            this.storeUi.search_count_all > 0 &&
                                            this.storeUi.search_results_simple_all[0] && (this.storeUi.search_value !== this.storeUi.search_results_simple_all[0].sku)
                                        )
                                        ?   <div 
                                                className = 'search-overlay__column search-overlay__column--alternatives'
                                            >
                                                <SearchResultAlternatives
                                                    bemClass = "--column"
                                                />
                                            </div>
                                        :   null
                                    }
                                    </React.Fragment>
                                }

                                {/* 
                                    SbC search results for specific isbn 
                                */}
                               
                                    {this.storeGeneral.searchShop === 'OWN' && this.storeUi.search_count_all === 1 &&
                                    this.storeUi.search_results_simple_all[0] && (this.storeUi.search_value === this.storeUi.search_results_simple_all[0].sku)
                                    ?   <div 
                                            className = 'search-overlay__column search-overlay__column--results'
                                        >
                                            <SearchResultAlternatives
                                                bemClass = "--full"
                                            />
                                        </div>
                                    :   null
                                    }

                                    {this.storeGeneral.searchShop === 'SB' && this.storeUi.search_count_all === 1 &&
                                    this.storeUi.search_results_simple_all[0] && (this.storeUi.search_value === this.storeUi.search_results_simple_all[0].sku)
                                    ?   <div 
                                            className = 'search-overlay__column search-overlay__column--results'
                                        >
                                            <SearchResultAlternatives
                                                bemClass = "--full"
                                            />
                                        </div>
                                    :   null
                                    }
                              

                                {/* 
                                    SbC search results for selected segment 
                                */}
                                <div 
                                    className = 'search-overlay__column search-overlay__column--results'
                                >
                                    {
                                        (((this.storeGeneral.searchShop === 'OWN' && this.storeUi.search_count_own > 0 && this.storeUi.searchTopItemTitleSku === '') || (this.storeUi.searchTopItemTitleSku !== '' && this.storeUi.search_count_own > 1)) ||
                                        ((this.storeGeneral.searchShop === 'SB' && this.storeUi.search_count_sb > 0 && this.storeUi.searchTopItemTitleSku === '') || (this.storeUi.searchTopItemTitleSku !== '' && this.storeUi.search_count_sb > 1)) ||
                                        ((this.storeGeneral.searchShop === 'ALL' && this.storeUi.search_count_all > 0 && this.storeUi.searchTopItemTitleSku === '') || (this.storeUi.searchTopItemTitleSku !== '' && this.storeUi.search_count_all > 1)))
                                        ?   <div>
                                                <SearchResultOverlay
                                                    count = {this.storeUi.search_count_sb}
                                                />
                                            </div>
                                        :   null
                                    }
                                </div>

                                {/* 
                                    SbC in case no results for all segments
                                */}
                                <div 
                                    className = 'search-overlay__column search-overlay__column--feedback-tips'
                                >
                                    {
                                        (this.storeUi.search_count_own === 0 &&
                                        this.storeUi.search_count_sb === 0 &&
                                        this.storeUi.search_count_all === 0) ||
                                        (this.storeGeneral.searchShop === 'OWN' && this.storeUi.search_count_own < 10) ||
                                        (this.storeGeneral.searchShop === 'SB' && this.storeUi.search_count_sb < 10) ||
                                        (this.storeGeneral.searchShop === 'ALL' && this.storeUi.search_count_all < 10)
                                        ?   <div>
                                                <FeedbackNoSearchResultsSuggestion
                                                
                                                />
                                            </div>
                                        :   null
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(SearchOverlay);
