import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";

import ImgCover from '../../elements/img-cover';
import BookLang from '../../elements/book-lang';
import BlockBookStock from '../block/block-book-stock';

import SearchBlockTopAuthor from './search-block-topauthor';
import SearchBlockTopTitle from './search-block-toptitle';
import SearchBlockTopScore from './search-block-topscore';

import * as utilities from '../../utilities/formats';
import * as content from '../../utilities/content';

const SearchOverlayItemTop = inject("stores") (
    observer (
        class SearchOverlayItemTop extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeStock = this.props.stores.storeStock;

                this.minLength = 5;
                //let minLength = this.storeGeneral.settings_list["minLengthSearchSuggestion"];

                this.state = {
                    hasTopItem: false,
                    elevatedHeader: 'Als beste resultaat is gevonden:'
                }
            }

            componentDidMount = async() => {
                let searchValue = this.props.searchValue.toLowerCase();
                let avail;
                let title;
                let topItemTitle = {};
                let hasTopItem = false;

                if (searchValue.length > this.minLength) {
                    this.storeUi.search_results_simple_all.map((item) => {
                        if (item.title) {
                            title = item.title.toLowerCase();
                        }
                        title = this.convertString(title);
                        avail = this.storeStock.checkStockAvailability('order', item);

                        //SbC check if topitem match
                        if ((title === searchValue) && avail) {
                            hasTopItem = true;
                            topItemTitle = item;
                        } else {
                            if ((title.startsWith(searchValue) || title.endsWith(searchValue) || title.includes(searchValue)) && avail && !hasTopItem) {
                                hasTopItem = true;
                                topItemTitle = item;
                            }
                        } 
                    })
               
                    if (hasTopItem) {
                        this.setState({
                            hasTopItem: hasTopItem,
                            item: topItemTitle,
                            topItemTitle: topItemTitle
                            
                        })
                    } else {
                        this.setState({
                            hasTopItem: hasTopItem,
                            item: null
                        })
                    }
                } else {
                    this.setState({
                        hasTopItem: hasTopItem,
                        item: null
                    })
                }
            }

            componentDidUpdate = async(prevProps) => {
                let searchValue = this.props.searchValue.toLowerCase();
                let avail;
                let title;
                let topItemTitle = {};
                let hasTopItem = false;

                //console.log('SbC items all', this.storeUi.search_results_simple_all)
                
                if ((prevProps.searchValue !== this.props.searchValue) || 
                    (prevProps.searchTopItemTitle !== this.props.searchTopItemTitle) ||
                    (prevProps.searchTopItemTitleSku !== this.props.searchTopItemTitleSku))
                {
                    if ((searchValue.length > this.minLength) && (this.storeUi.search_results_simple_all.length > 0)) {
                        // this.storeUi.search_results_simple_all.map((item) => {
                        //     //let item = this.storeUi.search_results_simple_all[0];
                        //     title = item.title && item.title.toLowerCase();
                        //     title = this.convertString(title);
                        //     avail = this.storeStock.checkStockAvailability('order', item);

                        //     //SbC check if topitem match
                        //     if ((title === searchValue) && avail) {
                        //         hasTopItem = true;
                        //         topItemTitle = item;
                        //     } else {
                        //         if ((title.startsWith(searchValue) || title.endsWith(searchValue) || title.includes(searchValue)) && avail && !hasTopItem) {
                        //             hasTopItem = true;
                        //             topItemTitle = item;
                        //         } else {
                        //             hasTopItem = false;
                        //         }
                        //     }     
                        // })
                        this.storeUi.search_results_simple_all.map((item) => {
                            if (item.title && (item.title.toLowerCase() === this.props.searchTopItemTitle)) {
                                hasTopItem = true;
                                topItemTitle = item;
                            }
                        })
                  
                        if (hasTopItem) {
                            this.setState({
                                hasTopItem: true,
                                item: topItemTitle,
                                topItemTitle: topItemTitle,
                                topItemTitleSku: topItemTitle.sku,
                            })
                        } else {
                            this.setState({
                                hasTopItem: false,
                                item: null,
                                topItemTitle: null
                            })
                        }
                    } else {
                        this.setState({
                            hasTopItem: false,
                            item: null,
                            topItemTitle: null
                        })
                    }
                }
            }

            getElevatedHeader = async() => {

            }

            handleOnClick = (result) => {
                this.storeUi.setCurrentBook(result.sku);

                //SbC set topnav to SHOP_ALL
                this.storeUi.setActiveToggleNav(3);
                this.storeGeneral.setActiveShop('SHOP_ALL');

                if (this.props.shop) {
                    this.storeGeneral.setActiveShop(this.props.shop);
                }
                
                let url;
                if (parseInt(this.storeGeneral.settings_list.seoTitles) === 1) {
                    //url = "/book/" + result.sku + "/" + id;
                    url = "/book/" + result.sku;
                } else {
                    url = "/book/" + result.sku;
                }
                this.props.history.push({
                    sku: result.sku,
                    pathname: url
                })

                this.storeUi.setSearchOverlay(false);
            }

            convertString(str) {
                let cStr = '';

                cStr = content.revertHTML(str);
                return cStr;
            }

            render() {
                return (               
                    (this.props.searchValue.length >= this.minLength) &&
                    <React.Fragment>
                        {                                       
                        ((this.storeUi.search_count_own !== 0 || this.storeUi.search_count_sb !== 0) && this.storeUi.search_count_all !== 0) && 
                            <React.Fragment>
                                {this.props.items && (this.props.items.length > 0) && 
                                 this.props.items[0] && 
                                 (this.props.searchTopItemTitleSku === this.props.items[0].sku)
                                    ?   <span>Ben je op zoek naar deze:</span>
                                    :   <span>Ben je op zoek naar deze:</span>
                                }
                            </React.Fragment>
                        }
                        
                        <ul 
                            className = "list--overlay2 --top-match --elevated"
                            //data-content={this.state.elevatedHeader}
                        >              
                            {
                            /* 
                                SbC item in spotlight based on author match
                            */
                            }

                            {/* {(process.env.REACT_APP_ENV === 'dev') &&  */}
                                {/* <span>Author match: {this.props.searchTopItemTitle} - {this.props.searchTopItemTitleSku}</span> */}
                            {/* } */}
                            <SearchBlockTopAuthor
                                searchShop = {this.storeGeneral.searchShop}
                                searchSegment = {this.storeGeneral.searchSegment}
                                toggle = {this.storeUi.active_togglenav}
                                searchValue = {this.props.searchValue}
                                item = {this.storeUi.search_results_simple_all[0]}
                                topItem = {this.state.topItemTitle}
                                topItemTitle = {this.props.searchTopItemTitle}
                                topItemTitleSku = {this.props.searchTopItemTitleSku}
                            />
                            
                            {
                            /* 
                                SbC item in spotlight based on title match
                            */
                            }

                            {/* {(process.env.REACT_APP_ENV === 'dev') && 
                                <span>Title match: {this.props.searchTopItemTitle} - {this.props.searchTopItemTitleSku}</span>
                            } */}
                            <SearchBlockTopTitle
                                searchShop = {this.storeGeneral.searchShop}
                                searchSegment = {this.storeGeneral.searchSegment}
                                toggle = {this.storeUi.active_togglenav}
                                searchValue = {this.props.searchValue}
                                items = {this.storeUi.search_results_simple_all}
                                item = {this.storeUi.search_results_simple_all[0]}
                                topItem = {this.state.topItemTitle}
                                topItemTitle = {this.props.searchTopItemTitle}
                                topItemTitleSku = {this.props.searchTopItemTitleSku}
                            />
                            
                            {
                            /* 
                                SbC top item based on comparing scores
                            */
                            }

                            {this.props.items && this.props.items[0] && (this.props.searchTopItemTitleSku !== this.props.items[0].sku) &&

                            ((this.storeUi.search_results_simple_all[0] !== null && this.storeUi.search_results_simple_sb[0] !== null) &&
                            (this.storeUi.search_results_simple_all.length > 0 && this.storeUi.search_results_simple_sb.length > 0) &&
                                (this.storeUi.search_results_simple_all[0].score && this.storeUi.search_results_simple_sb[0].score) &&
                                    (this.storeUi.search_results_simple_all[0].score > this.storeUi.search_results_simple_sb[0].score)) &&
                                        (this.storeUi.search_results_simple_all[0].sku !== (this.state.topItemTitle && this.state.topItemTitle.sku)) &&
                                            <React.Fragment>
                                                 {(process.env.REACT_APP_ENV === 'dev') && 
                                                    <span>Score match: {this.props.searchTopItemScore}</span>
                                                }
                                                <SearchBlockTopScore
                                                    searchValue = {this.props.searchValue}
                                                    itemsAll = {this.storeUi.search_results_simple_all}
                                                    itemsSB = {this.storeUi.search_results_simple_sb}
                                                    itemAll = {this.storeUi.search_results_simple_all[0]}
                                                    itemSB = {this.storeUi.search_results_simple_sb[0]}
                                                    topItem = {this.props.items[0]}
                                                /> 
                                            </React.Fragment>
                                
                            }
                        </ul>
                    </React.Fragment>
                                          
                )
            }
        }
    )
)

export default withRouter(SearchOverlayItemTop);
