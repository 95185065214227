import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

const TemplateContentTabs = inject("stores") (
    observer (
        class TemplateContentTabs extends Component {

            constructor(props) {
                super(props);
                this.props = props;
            }
 
            render() {
                return (
                    <div className={"block block--content-tabs"}>
                        {this.props.children}
                    </div>
                )
            }
        }
    )
)

export default TemplateContentTabs;
