import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Redirect } from 'react-router';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import Template from '../templates/template';
import TemplateContentTabs from '../templates/template-content-tabs';

import AccountProfile from '../components/account/account-profile';
import AccountOrders from '../components/account/account-orders';
import AccountTechnical from '../components/account/account-technical';
import AccountCommissions from '../components/account/account-commissions';

const AccountTab = inject("stores") (
    observer (
        class AccountTab extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    view: this.props.view
                }

                this.storeAuth = this.props.stores.storeAuth;
                this.storeUser = this.props.stores.storeUser;
                this.storeUi = this.props.stores.storeUi;
                
            }

            renderView(view) {
                switch(view) {
                    case "profile":
                    default:
                        return (
                            <AccountProfile />
                        )
                    case "technical":
                        return (
                            <AccountTechnical />
                        ) 
                    case "orders":
                        return (
                            <AccountOrders />                             
                        )
                    case "commissions":
                        return (
                            <AccountCommissions 
                                id = {this.storeUser.user_data.user_id}
                            />
                        ) 
                }
            }

            render() {
                return (
                    this.renderView(this.props.view)
                )
            }
        }
    )
)

const Account = inject("stores") (
    observer (
        class Account extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                
                this.storeAuth = this.props.stores.storeAuth;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;

                this.state = {
                    view: this.storeUi.app_interface.active_tab !== '' ? this.storeUi.app_interface.active_tab : 'profile'
                }
            }

            componentDidMount() {
                window.scrollTo(0, 0);
                //this.handleScroll();
                if ( this.props.match.params.tab !== undefined) {
                    this.setState({
                        view: this.props.match.params.tab
                    });
                }
            }

            componentDidUpdate = (prevProps) => {
                // if (this.state.scroll) {
                //     this.handleScroll();
                // }
                if (prevProps.match.params.tab !== this.props.match.params.tab) {
                    this.setState({
                        view: this.props.match.params.tab
                    });
                } 
            }

            handleContentTab = (e, view) => {
                if (view === 'orders') {
                    if (this.storeUser.user_data.is_loggedin) {
                        (async() => await this.storeOrder.getAccountOrders(this.storeUser.user_data.user_id) )();
                    }
                }

                let tab = "/account/" + view;
                this.props.history.push(tab);
                this.setState({
                    view: view
                })
            }
      
            render() {
                if (!this.storeUser.user_data.is_loggedin) {
                    return <Redirect 
                        push to={"/"} 
                    />;
                }
               
                const Li = (view) => {
                    const activeClass = ((view.view === this.state.view) || (view.view == 'profile' && this.state.view == undefined)) ? ' --active' : '';
                    return (
                        <li className={"content-tabs__listitem content-tabs__listitem--" + view.view + activeClass} onClick={(e) => this.handleContentTab(e, view.view)}>
                            <span>{i18n.t("tabs." + view.view)}</span>
                        </li>
                    )
                }

                return (
                    <Template
                        type = 'single-column'
                        header = "account"
                        noScroll = {true}
                    >
                        <div className="content-segment">
                            <TemplateContentTabs

                            >
                                <ul className="content-tabs__list">
                                    <Li 
                                        view = 'profile'
                                    />
                                    <Li 
                                        view = 'orders'
                                    />
                                    {this.storeAuth.user.is_partner &&
                                        <React.Fragment>
                                            <Li 
                                                view = 'commissions'
                                            />
                                            <Li 
                                                view = 'technical'
                                            />
                                        </React.Fragment>
                                    }
                                </ul>
                            </TemplateContentTabs>
                        </div>

                        <div className="content-segment">
                            <AccountTab
                                view = {this.state.view}
                            />
                        </div>
                        
                    </Template>
                )
            }
        }
    )
)

export default  withTranslation()(Account);
